import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, StylesContext } from '@material-ui/styles';
import moment from 'moment';
import { Card, CardContent, Grid, Typography, ButtonBase, Link, Modal } from '@material-ui/core';
import { createJsxJsxClosingFragment } from 'typescript';
import BlockIcon from '@material-ui/icons/Block';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import { ConciergeForm } from './components';
import { useSubscription,useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase, { auth } from "firebase/app";
import "firebase/auth";
import "firebase/database";


const APPOINTMENTS = gql`
subscription appointments($id: Int!) {
  appointments(where: {common_area_id: {_eq: $id}}) {
    activity
    appointment_length
    common_area_id
    created_at
    created_by
    datetime_scheduled
    id
    title
    updated_at
  }
}
`;
const GETAREA = gql`
query common_area($id: Int!) {
  common_area:common_areas_by_pk(id: $id) {
    id
    name
    common_area_type {
      id
      name
    }
  }
}
`;

const GETUSERROLEBYEMAIL = gql `
subscription getRole($email: String!) {
  users:users(where: {email: {_eq: $email}}) {
    first_name
    last_name
    email
    roles
    id
  }
}`;

const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor:theme.palette.background.paper,
      padding:theme.spacing(2),
      position: "absolute",
    },
    row:{
   // borderBottom:"1px #3e3e3e solid",
      borderradius: 10,
      boxshadow: '10px 10px 30px #000'
    },
    col:{
     // borderLeft:"0.5px #3e3e3e solid",
      textAlign:"center",
      // borderradius: 10,
      boxshadow: '10px 10px 30px #000'
    },
    mainContainer: {
      height:250,
      with:300,
      backgroundSize: 'cover'
    },
    modal: {
      display: 'flex',
      padding: theme.spacing(2),
      alignItems: 'center',
      justifyContent: 'center',
    },
    btn:{
      width:'100%',
      height:'100%',
      cursor: 'pointer',
      boxShadow: '10px 2px 30px rgba(0, 0, 0, 0.15)',
      
      
      borderRadius: '5px',
      padding: '20px', 
   
      backgroundColor: 'rgba(9, 73, 250, 0.1)',
      // border: "1px #3e3e3e solid",
      // :'',color:'rgba(9, 73, 250, 0.1
      // background: 'rgba(3,3,3,.8)',
      // overflow: 'hidden',
      opacity: 0.4,
      boxshadow: '10px 10px 30px #000'
    }
}));

const colorBtn = (existingAppoinment,belongsToCurrentUser) => {
  if(existingAppoinment != null && belongsToCurrentUser){
    return "red";
  } else if (existingAppoinment != null) {
    return "#1E90FF";
  }
  else {
    return "";
  }
};

const ConciergeView = props => {
  const [headers,setWeek] = useState([
    moment(),
    moment().add(1,'days'),
    moment().add(2,'days'),
    moment().add(3,'days'),
    moment().add(4,'days'),
    moment().add(5,'days'),
    moment().add(6,'days')
  ]);
  const [todayState, setToday] =  useState(moment());
  const [increaseWeek, setIncreaseWeek ] = useState (7);
    const { className, label, route, img, ...rest } = props;
    const { params:{id} } = props.match;
    const emailCustom =  firebase.auth().currentUser && firebase.auth().currentUser.email;
    const {data:userData,loading:userLoadingError,error:userDataError} = useSubscription(GETUSERROLEBYEMAIL,{variables:{email:emailCustom}});  
    const [datetimeSelected,setDatetimeSelected] = useState('');
    const [created_by,setCreatedBy] = useState(0);
    const [idSelected,setIdSelected] = useState({});
    const [openModal,setOpenModal] = useState(false);
    const { loading:loadingApp, error:errorApp, data:dataApp } = useSubscription(APPOINTMENTS,{variables:{id}});
    const { loading:loadingA, error:errorA,data:dataA } = useQuery(GETAREA,{variables:{id}})
    let history = useHistory();
    const classes = useStyles();
    const today = moment();
    today.hours(0);
    today.minutes(0);
    today.seconds(0);
    const scheduleIncreaseBy = 30; //0,30,60,90,etc minutes
    // const headers = [];
    const rrows = [];


    var startOfWeek = moment().startOf('week');
    var endOfWeek = moment().endOf('week');

// var days = [];
// days.push(moment(today).add(7,'days'));
// console.log(days);


var startOfWeek = moment().startOf('week');
var endOfWeek = moment().endOf('week');

var days = [];
var day = startOfWeek;

while (day <= endOfWeek) {
    days.push(day.toDate());
    day = day.clone().add(1, 'd');
}

//console.log(days);



    // { Headers are days, ciclo de 6 dias desde el arrego headers}
    // for(let i=0;i<=6;i++){
    //      headers.push(moment().add(i,'days'));
    // }
    {/* rows are hours */}
    for(let j=60*7;j<=(21*60);j=j+scheduleIncreaseBy){
      
      rrows.push(moment(today).hours(0).minutes(0).seconds(0).milliseconds(0).add(j,"minutes"));
    }

    const handdleModal = ()=>{
      setOpenModal(!openModal); 
    };

    const validateArea = () => {
      if (id == 9) {
        console.log("El area es pricketball")
      } console.log("El area no es pricketball")
    };

    const sumarDias = () => {  
      if(increaseWeek == 35) {
        console.log("you cant go any further marty!");
      }
      else {
      // today.add('days',increaseWeek)
      setIncreaseWeek(increaseWeek + 7 );
      setToday(todayState.add('days',increaseWeek));
        setWeek(headers => []);
       setWeek(headers => [...headers,
         moment().add(increaseWeek,'days'),
         moment().add(increaseWeek+1,'days'),
         moment().add(increaseWeek+2,'days'),
         moment().add(increaseWeek+3,'days'),
         moment().add(increaseWeek+4,'days'),
         moment().add(increaseWeek+5,'days'),
         moment().add(increaseWeek+6,'days'),
       ]);
       console.table(headers);
     }
    }

    const substractDays = () => {
      if (increaseWeek == 7 ){
        console.log("no juegues con eso marty");
      } else {
      setIncreaseWeek(increaseWeek - 7 );
      const dateForSend = headers[0].subtract(7, "days");
      let localArray = [];
      for(let i=0;i<=6;i++){
             localArray.push(moment(dateForSend).add(i,'days'));
         }
      makeArray(localArray);
    }
  }
  const makeArray= (parameter) => {
    setWeek(headers => parameter);
  }




    if (userLoadingError) 
    return <p>loading</p>
    return (
        <Grid className={classes.root} container >
          <Grid item xs={12}>
            <Grid container justify="space-between">
    {(loadingA)?<Typography variant="h3" >...</Typography>:<Typography variant="h3" 
    style={{textAlign:'center',flex:1}}>
      <span style={{fontWeight:'bold',textTransform:'uppercase'}}>
        {dataA.common_area.name}</span> <br /> {headers[0].format('DD/MM/YYYY')} - {headers[6].format('DD/MM/YYYY')}</Typography>}
         
        {(id == 1 || id == 2 || id == 3 || id == 8 || id == 9 || id == 10  ?  
               <Link
              component="button"
              variant="h3"
              onClick={()=>substractDays()}
            ><ArrowBackIcon />
            </Link>
               :  <div></div> )}
           {(id == 1 || id == 2 || id == 3 || id == 8 || id == 9 || id == 10  ?  
                <Link
              component="button"
              variant="h3"
              onClick={()=>sumarDias()}
            ><ArrowForwardIcon/>
            </Link>
               :  <div></div> )}

           
            <Link
              component="button"
              variant="h3"
              onClick={()=>history.goBack()}
            ><CloseIcon />
            </Link>
            </Grid>
          </Grid>





          <Grid item xs={12} >
            {/* headers  are days*/}
            <Grid container className={classes.row}>
              <Grid item xs={2}></Grid>

              {headers.map((current)=>{
                return (<Grid className={classes.col} item xs>
                  <Typography style={{textAlign:"center"}}>
                    {current.format('ddd')}
                  </Typography>
                  </Grid>);
              })}
            </Grid>  
          </Grid>
          {(loadingApp === true)?<Grid item xs={12}><Typography>loading........</Typography></Grid>:
    <Grid item xs={12}>
          
          {rrows.map((currentT)=>{
            return (
              <Grid container className={classes.row}>
                
                
                <Grid className={classes.col} item xs={2}>
                  <Typography style={{textAlign:"center"}}>
                    {moment(currentT).format("hh:mm a")}
                  </Typography>
                  </Grid>

               {/* aqui estan los dias */}
         {headers.map((currentD)=>
         {
                  // let selectedDateTime = currentT.day(currentD.day()).format();
                  let date = currentD.format("YYYY:MM:DD")+currentT.format('HH:mm:ss');
                  let selectedDateTime = moment(date,"YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");

                  let existingAppoinment = dataApp.appointments.find((current) => {
                    let selectedDateTimeTimestamp = moment(selectedDateTime).unix();
                    let startDateTime = moment(current.datetime_scheduled).unix();
                    let endDateTime = moment(current.datetime_scheduled).add(current.appointment_length,'minutes').unix();
                    //  return (selectedDateTimeTimestamp >= startDateTime && selectedDateTimeTimestamp < endDateTime);
                    return (selectedDateTimeTimestamp >= startDateTime && selectedDateTimeTimestamp < endDateTime);
                  
                  
                  
                  });



          // corregir aqui para filtrar registros por id
                  let belongsToCurrentUser = (existingAppoinment!=null && existingAppoinment.created_by === userData.users[0].id)?true:false;
                  let color = colorBtn(existingAppoinment,belongsToCurrentUser);
                  return (
                    <Grid className={classes.col} item xs>
                      
                      <Link
                        className={classes.btn}
                        // style={{backgroundColor:'rgba(9, 73, 250, 0.1)',border: (color === '')?"1px #3e3e3e solid":'',color:'rgba(9, 73, 250, 0.1)'}}
                        style={{backgroundColor:color,border: (color === '')?"1px #3e3e3e solid":'',color:'#FFF'}}
                        
                        component="button"
                        variant="body2"


                        onClick={() => {
                          setDatetimeSelected(selectedDateTime);
                          setCreatedBy(existingAppoinment);
                          setIdSelected((existingAppoinment != null)?existingAppoinment:{
                            activity:'',
                            appointment_length:0,
                            common_area_id:id,
                            created_by:1,
                            datetime_scheduled:selectedDateTime,
                            title:''
                          });
                          setOpenModal(!openModal);
                        }}
                      >
                       
                        
                        {(color !== '')?"":''}
                      </Link>
            </Grid>
                  );
                })} 



      </Grid>
            );
          })}
    </Grid>
        
          }
          <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={openModal}
          className={classes.modal}
          >
            <ConciergeForm handdleModal={handdleModal.bind(this)} datetime={datetimeSelected} appointmente_data={idSelected}  validateArea={id} createdby={created_by} />
          </Modal>
</Grid>
    );
}

ConciergeView.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    route: PropTypes.string,
    img: PropTypes.string,
    onclick: PropTypes.func
  };
  
  export default ConciergeView;