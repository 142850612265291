import React, { forwardRef } from 'react';
import { useHistory } from "react-router-dom";
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, StylesContext } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700,
    fontSize:32,
    textTransform:'uppercase',
    color:'#FFFFFF'
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  },
  mainContainer: {
    height:250,
    with:300,
    backgroundSize: 'cover'
  },
  pointer: {
    cursor: "pointer" 
  }
}));





const ConciergeItem = props => {
  const { className, label, route, img,idArea, ...rest } = props;
  let history = useHistory();

  
  const classes = useStyles();
  const validateArea = () => {
    if(idArea == 9){
      console.log("Es el id de prickball 1")
  
    } else {
    console.log("el areas no es de pricketball");
    } }
  


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.mainContainer}
            style={{backgroundImage:`url(https://ownersapp.costabonita.mx/images/concierge/${img})`}} 
          >
            <Grid item>
              <Link className={classes.pointer}
              onClick={()=>{
                history.push(route)
              }}>
              <Typography
                className={classes.title}
                gutterBottom
                variant="title"
              >
                {label}
              </Typography>
              </Link>
            </Grid>
          </Grid>
      </CardContent>
    </Card>
  );
};

ConciergeItem.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  route: PropTypes.string,
  img: PropTypes.string,
};

export default ConciergeItem;