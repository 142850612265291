import React, { useState, createRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Typography,
  Button,
  Collapse,
  TextField
} from '@material-ui/core';
import validate from 'validate.js';
import userDataStore from '../../UserDataStore';
import { useSubscription, useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import NesletterItem from './components/NewsletterItem';
import { func } from 'prop-types';
import firebase, { auth } from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import emailjs from 'emailjs-com';
import { sendEmailHelper } from 'helpers';

const schema = {
  title: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  description: {
    presence: { allowEmpty: true }
  },
  filename: {
    presence: {
      allowEmpty: true
    }
  }
};
const NEWSLETTERQL = gql`
  subscription newsletters {
    blog(
      where: { blog_type: { name: { _eq: "newsletter" } } }
      order_by: { id: desc }
    ) {
      Deshabilitado
      created_at
      created_by
      deleted
      description
      english
      filename
      id
      sended
      title
      blog_type {
        created_at
        id
        name
        updated_at
      }
      updated_at
    }
  }
`;

const CREATEGQL = gql`
  mutation CREATEITEM($object: blog_insert_input!) {
    insert_blog_one(object: $object) {
      created_at
      description
      filename
      id
      title
      updated_at
    }
  }
`;
const DELETEBLOG = gql`
  mutation MyMutation($id: Int!) {
    delete_blog_by_pk(id: $id) {
      id
    }
  }
`;
const GETUSERROLEBYEMAIL = gql`
  subscription getRole($email: String!) {
    users: users(where: { email: { _eq: $email } }) {
      first_name
      last_name
      email
      roles
    }
  }
`;
const USERSDATA = gql`
  query MyQuery {
    users(where: { send_notifications: { _eq: true } }) {
      first_name
      last_name
      email
      id
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    backgroundImage: 'url(/images/alberca.jpg)'
  },
  backwhite: {
    background: 'white'
  },
  fontcolor: {
    fontcolor: 'white'
  }
}));

const Newsletter = props => {
  const { userCondtion } = props;
  userDataStore.setTitle('');
  userDataStore.setTitle('newsletter');
  const emailCustom =
    firebase.auth().currentUser && firebase.auth().currentUser.email;
  const linkshare = createRef();
  const classes = useStyles();
  const { loading, error, data: dataNewsletter } = useSubscription(
    NEWSLETTERQL
  );
  const {
    loading: loadingUsers,
    error: errorUsers,
    data: dataUsers
  } = useQuery(USERSDATA);
  const {
    data: userData,
    loading: userLoadingError,
    error: userDataError
  } = useSubscription(GETUSERROLEBYEMAIL, {
    variables: { email: emailCustom }
  });

  //settings forms
  const [addItem, { data: itemData }] = useMutation(CREATEGQL);
  const [showForm, setShowForm] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const onFileChange = event => {
    // Update the state
    setSelectedFile(event.target.files[0]);
  };

  const fileValidator = () => {
    if (selectedFile == null) {
      alert('No file selected');
      saveTest();
    } else {
      onFileUpload();
    }
  };

  const onFileUpload = () => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append('file', selectedFile, selectedFile.name);
    return fetch('https://services.costabonita.mx/upload', {
      method: 'POST',
      body: formData
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(json) {
        let values = formState.values;
        values.filename = `${json.data.folder}/${json.data.name}`;
        values.blog_type_id = 2;
        values.Deshabilitado = '';
        values.english = '';
        sendEmailNotification(`${json.data.folder}/${json.data.name}`);
        addItem({
          variables: {
            object: values
          }
        });
      });
  };

  const saveTest = () => {
    let values = formState.values;
    console.log('desde funcion save test ');
    addItem({
      variables: {
        object: {
          title: values.title,
          description: values.description,
          filename: 'images/1gfq8fecrsmuv9crjweafd.png',
          //filename: `${json.data.folder}/${json.data.name}`,
          Deshabilitado: '0',
          english: '0',
          blog_type_id: 2
        }
      }
    });
    //sendEmailNotification();
  };

  const sendEmailNotification = Link => {
    console.log('sendEmailfunction');
    const usersEmail = dataUsers.users.map(usersEmailsList => {
      return usersEmailsList.email;
    });
    console.log(usersEmail);
    sendEmailHelper(['inupumper@gmail.com','carloscostabonita@yahoo.com.mx',...usersEmail], 'notNews', Link, 'Newsletter');
  };

  const handleOnSubmit = event => {
    fileValidator();

    event.preventDefault();
  };
  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;
  //END FORMS
  if (userLoadingError || loadingUsers) return <p>loading</p>;

  return (
    <div className={classes.root}>
      {loading === true ? (
        <Grid>
          <Typography>LOADING......</Typography>
        </Grid>
      ) : (
        <Grid container spacing={4}>
          <Grid container justify="center">
            <Grid item xs={6}>
              <form onSubmit={handleOnSubmit} className={classes.form}>
                {userData.users[0].roles == 'admin' ? (
                  <Button
                    color={showForm ? 'secondary' : 'primary'}
                    fullWidth
                    type="reset"
                    size="large"
                    variant="contained"
                    onClick={event => {
                      setShowForm(!showForm);
                      setFormState(formState => ({
                        ...formState,
                        values: {}
                      }));
                    }}>
                    {showForm ? 'CANCEL' : 'CREATE'}
                  </Button>
                ) : (
                  <div></div>
                )}

                <Collapse in={showForm}>
                  <TextField
                    className={classes.separation}
                    error={hasError('title')}
                    fullWidth
                    helperText={
                      hasError('title') ? formState.errors.title[0] : null
                    }
                    label="Title"
                    name="title"
                    className={classes.backwhite}
                    onChange={handleChange}
                    value={formState.values.title || ''}
                    variant="outlined"
                  />
                  <TextField
                    className={(classes.separation, classes.backwhite)}
                    error={hasError('description')}
                    fullWidth
                    helperText={
                      hasError('description')
                        ? formState.errors.description[0]
                        : null
                    }
                    multiline
                    label="Description"
                    name="description"
                    onChange={handleChange}
                    value={formState.values.description || ''}
                    variant="outlined"
                    rows={4}
                  />

                  <TextField
                    className={classes.separation}
                    label="File"
                    onChange={onFileChange}
                    type="file"
                  />
                  <TextField
                    fontcolor="white"
                    type="hidden"
                    name="filename"
                    onChange={handleChange}
                    value={formState.values.filename || ''}
                  />
                  <Button
                    className={classes.separation}
                    color="primary"
                    //disabled={((!formState.isValid) || loading)}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained">
                    SAVE
                  </Button>
                </Collapse>
              </form>
            </Grid>
          </Grid>
          {dataNewsletter.blog.map(current => {
            return (
              <Grid item xs={12}>
                <NesletterItem
                  title={current.title}
                  description={current.description}
                  url={current.filename}
                  created={`${current.created_at}`}
                  userType={userData.users[0].roles}
                  id={`${current.id}`}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </div>
  );
};

export default Newsletter;
