import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import moment from 'moment';

import { useHasuraUser } from 'hooks';
import { formattedNumber } from 'helpers';

const TRANSACTIONS_PER_PAGE = 20;

const GET_USER_TRANSACTIONS = gql`
  query GetUserTransactions($id: Int!, $offset: Int!, $limit: Int!) {
    transactions_aggregate(where: { statement_account_id: { _eq: $id } }) {
      aggregate {
        count
      }
    }
    statement_account: statement_account_by_pk(id: $id) {
      id
      total
    }
    transactions(
      where: { statement_account_id: { _eq: $id } }
      offset: $offset
      limit: $limit
    ) {
      amount
      exchange
      en_description
      currency
      transaction_type
      is_tip
    }
  }
`;

const AccountStatement = () => {
  const { user } = useHasuraUser();
  const [paginationInfo, setPaginationInfo] = useState({
    currentPage: 1,
    maxPages: 1
  });
  const { data } = useQuery(GET_USER_TRANSACTIONS, {
    variables: {
      id: user?.id || 0,
      offset: (paginationInfo.currentPage - 1) * TRANSACTIONS_PER_PAGE,
      limit: TRANSACTIONS_PER_PAGE
    }
  });

  useEffect(() => {
    if (data?.transactions_aggregate) {
      const maxPages = Math.ceil(
        (data.transactions_aggregate.aggregate?.count || 0) /
          TRANSACTIONS_PER_PAGE
      );

      setPaginationInfo(prevState => ({ ...prevState, maxPages }));
    }
  }, [data]);

  return (
    <Grid alignItems="center" container>
      <Typography variant="h6">Account statement</Typography>
      <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date and time</TableCell>
              <TableCell>Transaction type</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.transactions ? (
              <TableRow style={{ backgroundColor: 'white' }}>
                <TableCell align="center" colSpan={3}>
                  No transactions found
                </TableCell>
              </TableRow>
            ) : (
              data?.transactions?.map((t, index) => (
                <TableRow key={index} style={{ backgroundColor: 'white' }}>
                  <TableCell>
                    {moment(t.created_at).format('MMMM Do YYYY, h:mm:ss a')}
                  </TableCell>
                  <TableCell>{t.transaction_type}</TableCell>
                  <TableCell align="right">
                    $ {formattedNumber(t.amount.toFixed(2))}
                  </TableCell>
                </TableRow>
              ))
            )}
            <TableRow style={{ backgroundColor: 'white' }}>
              <TableCell align="right" colSpan={2}>
                Total:
              </TableCell>
              <TableCell align="right">
                ${' '}
                {formattedNumber(
                  data?.statement_account?.total?.toFixed(2) || 0
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid
        container
        item
        justifyContent="center"
        spacing={2}
        style={{ marginTop: 20 }}
        xs={12}>
        <Grid item>
          <Pagination
            color="primary"
            count={paginationInfo.maxPages}
            onChange={(_, page) =>
              setPaginationInfo(prevState => ({
                ...prevState,
                currentPage: page
              }))
            }
            page={paginationInfo.currentPage}
            shape="rounded"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AccountStatement;
