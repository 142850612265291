import { useState, useEffect } from 'react';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

const GET_USER_ID = gql`
  query GetUserId($firebaseId: String!) {
    users(where: { uid: { _eq: $firebaseId } }) {
      apartment {
        statement_accounts(where: { owner: { _eq: true } }) {
          id
        }
      }
    }
  }
`;

const useHasuraUser = () => {
  const [hasuraUser, setHasuraUser] = useState(null);
  const firebaseId = localStorage.getItem('uuid') || '';
  const { data } = useQuery(GET_USER_ID, { variables: { firebaseId } });

  useEffect(() => {
    if (data?.users && data.users.length >= 1) {
      const [dbUser] = data.users;
      const statement = dbUser?.apartment?.statement_accounts[0];

      setHasuraUser({ id: statement?.id || 0 });
    }
  }, [data]);

  return { user: hasuraUser };
};

export default useHasuraUser;
