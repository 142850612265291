import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { UsersToolbar, UsersTable } from './components';

import UserDataStore from '../../UserDataStore';
import { useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase, { auth } from "firebase/app";
import "firebase/auth";
import "firebase/database";


const GETARRIVALS = gql`
subscription MySubscription {
  arrivals(order_by: {created_at: desc}) {
    id
    arrival_date
    apartment_id
    car_color
    comments
    created_at
    created_by
    model_car
    name
    plates_number
    transport_id
    transport_type
    updated_at
    apartment {
      building_id
      created_at
      id
      number
      updated_at
      user_id
      building {
        created_at
        id
        name
        updated_at
      }
    }
  }
}

`;

const GETUSERARRIVALS = gql`
subscription MyQuery {
  lessors (order_by: {id: desc}) {
    contact_id
    diner_id
    disabled
    emergency_contact_id
    id
    name
    number
    user_id
    contact {
      first_name
      id
      last_name
      birth_date
      contact_phones {
        phone {
          id
          number
        }
      }
      contact_emails {
        email {
          id
          direction
        }
      }
      contact_addresses {
        address {
          id
          city
          exterior
          neighborhood
          street
          zip_code
        }
      }
    }
  }
}

`;
const GETUSERROLEBYEMAIL = gql `
subscription getRole($email: String!) {
  users:users(where: {email: {_eq: $email}}) {
    first_name
    last_name
    id
    email
    roles
  }
}`;

const GERARRIVALSFORUSERID = gql`
subscription MySubscription($_eq: Int !) {
  arrivals(where: {created_by: {_eq: $_eq}}) {
    id
    car_color
    comments
    arrival_date
    apartment_id
    created_at
    created_by
    name
    model_car
    plates_number
    transport_id
    transport_type
    updated_at
  }
}`;


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const Arrivals = (props) => {
  UserDataStore.setTitle('Arrivals');
  const classes = useStyles();
  const [query,setQuery] = useState('');
  var userIdCustom = 0;
  const {loading,error,data} = useSubscription(GETARRIVALS);
  
  const emailCustom =  firebase.auth().currentUser && firebase.auth().currentUser.email;
  const {data:userData,loading:userLoading,error:userDataError} = useSubscription(GETUSERROLEBYEMAIL,{variables:{email:emailCustom}});
  const HanddleQueryChange = (event) => {
    setQuery(event.target.value);
  };
  

  if(loading||userLoading)
  return (
    <div className={classes.root}>
      <UsersToolbar query={query} setQuery={HanddleQueryChange.bind(this)} />
      <div className={classes.content}>
        <h1>loading.......</h1>
      </div>
    </div>
  );
  try {
    return (
      <div className={classes.root}>
        <UsersToolbar query={query} setQuery={HanddleQueryChange.bind(this)}/>
        <div className={classes.content}>
          {/* <UsersTable arrivals={data.lessors} search={query}/> */}
          <UsersTable arrivals={data.arrivals} search={query}/> 
        </div>
      </div>
    );
  } catch (errorCatch) {
    return (
      <div className={classes.root}>
        <UsersToolbar query={query} setQuery={HanddleQueryChange.bind(this)}/>
        <div className={classes.content}>
          <h1>{error.message}</h1>
        </div>
      </div>
    );
  }
  
};


export default Arrivals;
