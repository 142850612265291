import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { 
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Grid,
    Button,
    TextField, } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useQuery,useMutation,useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import CustomAccordion from 'components/CustomAccordion';
import { set } from 'mobx';
import firebase, { auth } from "firebase/app";
import "firebase/auth";
import "firebase/database";

const GETAPARTMENT = gql`
query getapartments {
    apartment(where: {user_id: {_is_null: false}}) {
      id
      number
      updated_at
      created_at
      building_id
      building {
        created_at
        id
        updated_at
        name
      }
      user_id
      user {
        first_name
        last_name
        id
      }
    }
  }
`;

const APARMENTS = gql`
query MyQuery {
  apartment(order_by: {number: asc, building_id: asc}) {
    id
    number
    created_at
    building_id
    user_id
  }
}
`;

const INSERTARRIVAL = gql`
mutation MyMutation($object: arrivals_insert_input!) {
  insert_arrivals_one(object: $object) {
    id
    name
    arrival_date
    apartment_id
    model_car
    car_color
    plates_number
    transport_type
    updated_at
    created_by
    created_at
    comments
    transport_id
  }
}
`;

const INSERTVISIT = gql`
mutation inservisit($object: visits_insert_input!) {
  insert_visits_one(object: $object) {
    apartment_id
    arrives_at
    arrives_by
    canceled_at
    canceled_by
    car_color
    checking_in
    checking_in_by
    checking_out
    checking_out_by
    comments
    created_at
    created_by
    id
    lessor_id
    name
    output_comments
    plates_number
    status
    transport_type
    updated_at
    visitors_numbers
  }
}
`;

const GETUSERROLEBYEMAIL = gql `
subscription getRole($email: String!) {
  users:users(where: {email: {_eq: $email}}) {
    first_name
    last_name
    id
    email
    roles
  }
}`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      spacing={3}
      container
      {...other}
    >
      {value === index && (
        children
      )}
    </Grid>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles(() => ({
  root: {}
}));

const Create = props => {
    const { className, handleClose, ...rest } = props;
    const emailCustom =  firebase.auth().currentUser && firebase.auth().currentUser.email;
    const {data:userData,loading:userLoading,error:userDataError} = useSubscription(GETUSERROLEBYEMAIL,{variables:{email:emailCustom}});
    const { loading:loadingApartment, error:errorApartment, data:dataApartment } = useQuery(GETAPARTMENT);
    const { loading:loadingApartment2, error:errorApartment2, data:dataApartment2 } = useQuery(APARMENTS);
    const [ addVisit, { data:dataInsertedVisit,loading:loadingInsertedVisit } ] = useMutation(INSERTARRIVAL);
    const classes = useStyles();
    const comesByOption = [
      {label:'', value:''},
      {label:'Car', value:'Car'},
      {label:'Airplane', value:'Airplane'},
      {label:'Bus', value:'Bus'}
    ];


    const [values, setValues] = useState(userLoading ? 
    {
    name: '',
    arrival_date: '',
    apartment_id: 0,
    model_car: '',
    car_color: '',
    plates_number: '',
    transport_type: '',
    created_by: 0,
    comments: '',
    transport_id: 0
    }
    
    
    :
    {
      name: '',
      arrival_date: '',
      apartment_id: 0,
      model_car: '',
      car_color: '',
      plates_number: '',
      transport_type: '',
      created_by: userData.users[0].id,
      comments: '',
      transport_id: 0
      }
    );

  
    const handleChangeContactElements = (contactType,itemType,newRow,action=0) => {
      if(action === 0){
        let contactTypeVal =  values[contactType];
        contactTypeVal[itemType].push(newRow);
        setValues({
          ...values,
          [contactType]: contactTypeVal
        });
      } else {
        let contactTypeVal =  values[contactType];
        console.log(newRow);
        contactTypeVal[itemType] = contactTypeVal[itemType].filter((item)=>{
          console.log(item.tempid);
          return item.tempid != newRow.tempid
        });
        setValues({
          ...values,
          [contactType]: contactTypeVal
        });
      }
    };  
  
    const handleChange = event => {
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });
    };

    const handleChangeContact = event => {
        let keys = event.target.name.split('.')
        setValues({
            ...values,
            [keys[0]]: {
                ...values[keys[0]],
                [keys[1]]:event.target.value
            }
        })
    }

    const handleChangeContactAccordion = event => {
      let tempid = event.target.id;
      let keys = event.target.name.toLowerCase().split('.')
      let currentValuesUpdated = values[keys[0]][keys[1]].map((currentmap)=>{
        if(currentmap.tempid === tempid){
          currentmap[keys[2]] = event.target.value;
        }
        return currentmap;
      })
      setValues({
          ...values,
          [keys[0]]: { //contact
              ...values[keys[0]],
              [keys[1]]: currentValuesUpdated
          }
      })
    }
  
    const [tabvalue, setTabvalue] = React.useState(0);
  
    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
      setTabvalue(newValue);
    };
  
  


    const handleSubmit = event => {
       addVisit({
         variables:{
           object:{
            name: values.name,
            arrival_date: values.arrival_date,
            apartment_id: values.apartment_id,
            model_car: values.model_car,
            car_color: values.car_color,
            plates_number: values.plates_number,
            transport_type: values.transport_type,
            created_by: userData.users[0].id,
            comments: values.comments,
            transport_id: values.transport_id
         }}
         
       }).then(()=>{
         onBack()
       });
      console.log(values)
      console.table(userData.users[0])
      event.preventDefault();
    };
  
    const onBack = () =>{
      window.history.back();
    }

    if(loadingApartment || loadingApartment2 || userLoading)
    return (
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >loading......</Card>
    )

    return (
     
    <Grid
    container
    spacing={4}
    justify="center"
    >
       {console.table(userData.users[0].id)}
       
        <Grid
        item
        xs={10}
        >
            <Card
            {...rest}
            className={clsx(classes.root, className)}
            >
            <form
                onSubmit={handleSubmit}
                autoComplete="off"
            >
                <CardHeader
                subheader="Capture arrival information"
                title="New Arrival"
                />
                <Divider />
                <CardContent>
                <Grid
                    container
                    spacing={3}
                >
                    <Tabs value={tabvalue} onChange={handleTabChange} aria-label="simple tabs example">
                    <Tab label="Registration" {...a11yProps(0)} />
                    <Tab label="Contact Info" {...a11yProps(1)} />
                    <Tab label="Emergency Info" {...a11yProps(2)} />
                    </Tabs>
                </Grid>
                <Grid
                    style={{marginTop:20}}
                    container
                    spacing={3}
                >
                    <TabPanel value={tabvalue} index={0} container>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        {(dataApartment.apartment.find(x=>x.id == values.apartment_id))?dataApartment.apartment.filter(y=>y.id == values.apartment_id).map((apartment)=>{
                        return (
                            <TextField
                            fullWidth
                            helperText=""
                            label="Owner or Guest"
                            margin="dense"
                            name="Huesped"
                            InputProps={{
                                readOnly: true,
                            }}
                            //onChange={handleChange}
                            required
                            value={`${apartment.user.first_name} ${apartment.user.last_name}`}
                            variant="outlined"
                            />
                        );
                        }):
                        (
                        <TextField
                        fullWidth
                        helperText=""
                        label="Owner or Guest"
                        margin="dense"
                        name="Huesped"
                        //onChange={handleChange}
                        InputProps={{
                            readOnly: true,
                        }}
                        required
                        value={''}
                        variant="outlined"
                        />
                        )
                        }
                    </Grid>
                    <Grid
                        item
                        md={4}
                        xs={12}
                    >
                        <TextField
                        fullWidth
                        label="Condominium"
                        margin="dense"
                        name="apartment_id"
                        onChange={handleChange}
                        select
                        // eslint-disable-next-line react/jsx-sort-props
                        SelectProps={{ native: true }}
                        value={values.apartment_id}
                        variant="outlined"
                        >
                        <option value={0}>selecionar</option>
                         {dataApartment2.apartment.map(apartment => {
                            return (
                            <option
                            key={apartment.id}
                            value={apartment.id}>
                                {`${apartment.number}-${apartment.building_id == 1 ? "a" :
                                                        apartment.building_id == 2 ? "b" :
                                                        apartment.building_id == 3 ? "c" : "d" 
                                                         }`}
                            </option>
                            );
                        })} 
                        </TextField>
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                        fullWidth
                        helperText="Who is comming?"
                        label="Name"
                        margin="dense"
                        name="name"
                        onChange={handleChange}
                        required
                        value={values.name}
                        variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                        fullWidth
                        helperText="When is comming?"
                        label="Arrival DateTime"
                        margin="dense"
                        name="arrival_date"
                        onChange={handleChange}
                        value={values.arrival_date}
                        required
                        type="datetime-local"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        // InputProps={{inputProps:{
                        //   min: '2020-10-06T22:22:55'
                        // }}}
                        //inputProps={{ min: '2020-10-06T22:22:55'}}
                        variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={4}
                        xs={12}
                    >
                        <TextField
                        fullWidth
                        label="Comes by"
                        margin="dense"
                        name="transport_type"
                        onChange={handleChange}
                        select
                        // eslint-disable-next-line react/jsx-sort-props
                        SelectProps={{ native: true }}
                        value={values.transport_type}
                        variant="outlined"
                        >
                        {comesByOption.map(option => (
                            <option
                            key={option.value}
                            value={option.value}
                            >
                            {option.label}
                            </option>
                        ))}
                        </TextField>
                    </Grid>
                    <Grid
                        item
                        md={4}
                        xs={12}
                    >
                        <TextField
                        fullWidth
                        helperText="Type / model car (optional)"
                        label="Type / model car"
                        margin="dense"
                        name="model_car"
                        onChange={handleChange}
                        value={values.model_car}
                        variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={4}
                        xs={12}
                    >
                        <TextField
                        fullWidth
                        helperText="Tell us what color (optional)"
                        label="Color"
                        margin="dense"
                        name="car_color"
                        onChange={handleChange}
                        value={values.car_color}
                        variant="outlined"
                        />
                    </Grid>
                     <Grid
                        item
                        md={4}
                        xs={12}
                    >
                        <TextField
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        fullWidth
                        helperText="comments"
                        label="Comment"
                        margin="dense"
                        name="comments"
                        onChange={handleChange}
                        value={values.Comments}
                        variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={4}
                        xs={12}
                    >
                        <TextField
                        fullWidth
                        helperText="Plates Number (optional)"
                        label="Plates Number"
                        margin="dense"
                        name="plates_number"
                        onChange={handleChange}
                        value={values.plates_number}
                        variant="outlined"
                        />
                    </Grid>
                   
                   

                  
                   




                    {/* </TabPanel>
                    <TabPanel value={tabvalue} index={1} container>
                    <Grid
                    item
                    md={6}
                    xs={12}
                    >
                    <TextField
                        fullWidth
                        label="First name"
                        margin="dense"
                        name="contact.first_name"
                        onChange={handleChangeContact}
                        value={values.contact.first_name}
                        variant="outlined"
                    />
                    </Grid>
                    <Grid
                    item
                    md={6}
                    xs={12}
                    >
                    <TextField
                        fullWidth
                        label="Last Name"
                        margin="dense"
                        name="contact.last_name"
                        onChange={handleChangeContact}
                        value={values.contact.last_name}
                        variant="outlined"
                    />
                    </Grid>
                    <Grid
                    item
                    md={6}
                    xs={12}
                    >
                    <CustomAccordion
                    handleChangeContactElements={handleChangeContactElements.bind(this)}
                    handleChangeContactAccordion={handleChangeContactAccordion.bind(this)}
                    ContactType="contact"
                    Value={values.contact}
                    Categories={[
                        "Address",
                        "Emails",
                        "Phones"
                    ]}
                    />
                    </Grid>
                    </TabPanel>
                    <TabPanel value={tabvalue} index={2} container>
                    <Grid
                    item
                    md={6}
                    xs={12}
                    >
                    <TextField
                        fullWidth
                        label="First name"
                        margin="dense"
                        name="emergency_contact.first_name"
                        onChange={handleChangeContact}
                        value={values.emergency_contact.first_name}
                        variant="outlined"
                    />
                    </Grid>
                    <Grid
                    item
                    md={6}
                    xs={12}
                    >
                    <TextField
                        fullWidth
                        label="Last Name"
                        margin="dense"
                        name="emergency_contact.last_name"
                        onChange={handleChangeContact}
                        value={values.emergency_contact.last_name}
                        variant="outlined"
                    />
                    </Grid>
                    <Grid
                    item
                    md={6}
                    xs={12}
                    >
                    <CustomAccordion
                    handleChangeContactElements={handleChangeContactElements.bind(this)}
                    handleChangeContactAccordion={handleChangeContactAccordion.bind(this)}
                    ContactType="emergency_contact"
                    Value={values.emergency_contact}
                    Categories={[
                        "Addresses",
                        "Emails",
                        "Phones"
                    ]}
                    />
                    </Grid>*/}



                </TabPanel>  
                 </Grid> 
                 </CardContent> 
                
                <Divider />
                <CardActions>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    //onClick={saveInfo}
                >
                    Save
                </Button>
                <Button
                    variant="contained"
                    onClick={() => onBack() }
                >
                    Cancel
                </Button>
                </CardActions>
            </form>
            </Card>
        </Grid>
    </Grid>)
};

Create.propTypes = {
    className: PropTypes.string
};

export default Create;