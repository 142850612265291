import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/styles';
import {Grid,Card,CardHeader,Divider,CardContent,TextField,Button,CardActions, Snackbar} from "@material-ui/core";
import moment from 'moment';
import { useQuery,useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Alert } from '@material-ui/lab';
import { conditionallyUpdateScrollbar } from 'reactstrap/lib/utils';



const APARMENTS = gql`
query MyQuery {
  apartment(order_by: {number: asc, building_id: asc}) {
    id
    number
    created_at
    building_id
    user_id
  }
}
`;


const GETAPARTMENT = gql`
query getapartments($user_id: Int) {
  apartment(where: {user_id: {_is_null: true}}, order_by: {id: asc, building: {name: asc}}) {
    id
    number
    updated_at
    created_at
    building_id
    building {
      created_at
      id
      updated_at
      name
    }
    user_id
    user {
      first_name
      last_name
      id
    }
  }
}
`; 
const UPDATEAPARTMENT0 = gql`
mutation MyMutation($_eq: Int !) {
  update_apartment(where: {user_id: {_eq: $_eq}}, _set: {user_id: null}) {
    returning {
      id
      number
      user_id
    }
  }
}
`;


const UPDATEUSERDATA = gql`
mutation MyMutation($id: Int!, $email: String!, $first_name: String!, $last_name: String!, $roles: roles!, $apartment_id: Int!) {
  update_users_by_pk(pk_columns: {id: $id}, _set: {email: $email, first_name: $first_name, last_name: $last_name, roles: $roles}) {
    email
    first_name
    id
    last_name
    phone_number
    roles
  }
  update_apartment_by_pk(pk_columns: {id: $apartment_id}, _set: {user_id: $id}) {
    id
    building_id
    created_at
    number
    updated_at
    user_id
  }
}
`;

const GETUSER = gql`
query MyQuery($id: Int!) {
    user:users_by_pk(id: $id) {
      email
      first_name
      created_at
      id
      img_profile
      last_name
      phone_number
      public_contact_info
      roles
      uid
      updated_at
      user_name
      apartments {
        building_id
        created_at
        id
        number
        updated_at
        building {
          created_at
          id
          name
          updated_at
        }
      }
    }
  }
`;

const useStyles = makeStyles(() => ({
  root: {}
}));


const Edit = props => {

    const { className, handleClose, ...rest } = props;
    const {id} = props.match.params;
    const [updateUser, { data:updatedUserData,loading:updatedLoadingUser }] = useMutation(UPDATEUSERDATA);
    const [updateCondoUserId0, {data:dataCondoSet0, loading:loadingCondoSet0,}]=useMutation(UPDATEAPARTMENT0);
    const {data:userData,loading:userLoading,error:userError} = useQuery(GETUSER,{variables:{id}});
    // const { loading:loadingApartment, error:errorApartment, data:dataApartment } = useQuery(GETAPARTMENT);
    const { loading:loadingApartment, error:errorApartment, data:dataApartment } = useQuery(GETAPARTMENT);
    const { loading:loadingApartment2, error:errorApartment2, data:dataApartment2 } = useQuery(APARMENTS);
    let history = useHistory();
    const {refetch:createfirebase} = useQuery(GETAPARTMENT);
    const classes = useStyles();
    const [floatrAlert, setFloatAlert] = useState({
      message:'',
      visible:false,
      severity:'info'
    });
    const [values, setValues] = useState(userLoading ? 
    {
        id: id,
        first_name: '',
        last_name: '',
        email: '',
        apartment_id:0,
        roles:''
    }
    :
    {
        id: id,
        first_name: userData.user.first_name,
        last_name: userData.user.last_name,
        email: userData.user.email,
        apartment_id: (userData.user.apartments.length > 0)?userData.user.apartments[0].id:0,
        roles: "user"
    }
    );

    const handleCloseAlert = ()=>{
      history.goBack();
    };
    
    const saveInfo = () => {
      // handleCloseAlert()
     
    }

    const handleChange = event => {
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });
    };
    
    const handleSubmit = event => {
      updateUser({variables:values}).then(()=>{
        setFloatAlert({
          message:"Usuario editado",
          visible: true,
          severity: 'success'})
      })
      event.preventDefault();
    };


    if(loadingApartment || loadingApartment2 || userLoading  )
    return (
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >loading......</Card>
    )
    return (
    <Grid
    container
    spacing={4}
    justify="center"
    >
        {/* {console.log(JSON.stringify(dataApartment))} */}
        <Grid item xs={10}>
        <Card
            {...rest}
            className={clsx(classes.root, className)}
            >
            <form
                onSubmit={handleSubmit}
                autoComplete="off"
            >
                <CardHeader
                subheader="Capture user information"
                title="Edit User"
                />
                <Divider />
                <CardContent>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                    item
                    md={6}
                    xs={12}
                    >
                    <TextField
                        fullWidth
                        label="Condominium"
                        margin="dense"
                        name="apartment_id"
                        onChange={handleChange}
                        select
                        // eslint-disable-next-line react/jsx-sort-props
                        SelectProps={{ native: true }}
                        value={values.apartment_id}
                        variant="outlined"
                    >
                        <option value={0}>selecionar</option>
                        {dataApartment2.apartment.map(apartment => {
                            return (
                            <option
                            key={apartment.id}
                            value={apartment.id}
                            >
                                {`${apartment.number}-${apartment.building_id == 1 ? "a" :
                                                        apartment.building_id == 2 ? "b" :
                                                        apartment.building_id == 3 ? "c" : "d" 
                                                         }`}
                            </option>
                            );
                        })} 
                        
                         
                    </TextField>
                    </Grid>
                    <Grid
                    item
                    md={6}
                    xs={12}
                    >
                    <TextField
                        fullWidth
                        helperText="Email"
                        label="Email"
                        margin="dense"
                        name="email"
                        onChange={handleChange}
                        required
                        InputProps={{readOnly:true}}
                        value={values.email}
                        variant="outlined"
                    />
                    </Grid>
                    <Grid
                    item
                    md={6}
                    xs={12}
                    >
                    <TextField
                        fullWidth
                        helperText="First name"
                        label="First name"
                        margin="dense"
                        name="first_name"
                        onChange={handleChange}
                        required
                        value={values.first_name}
                        variant="outlined"
                    />
                    </Grid>
                    <Grid
                    item
                    md={6}
                    xs={12}
                    >
                    <TextField
                        fullWidth
                        helperText="Last name"
                        label="Last name"
                        margin="dense"
                        name="last_name"
                        onChange={handleChange}
                        required
                        value={values.last_name}
                        variant="outlined"
                    />
                    </Grid>
                    <Grid
                    item
                    md={6}
                    xs={12}
                    >
                    <TextField
                        fullWidth
                        label="Role"
                        margin="dense"
                        name="roles"
                        onChange={handleChange}
                        required
                        value={values.roles}
                        variant="outlined"
                        select
                        SelectProps={{ native: true }}
                    >
                        <option key="user" value="user">user</option>
                        <option key="concierge" value="concierge">concierge</option>
                        <option key="portico" value="portico">portico</option>
                        <option key="admin" value="admin">admin</option>
                        <option key="board" value="board">board</option>
                    </TextField>
                    </Grid>
                </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={saveInfo}
                >
                    Save
                </Button>
                <Button
                    variant="contained"
                    onClick={handleCloseAlert}
                >
                    Cancel
                </Button>
                </CardActions>
            </form>
        </Card>
        </Grid>
        <Snackbar anchorOrigin={{ vertical:'top', horizontal:'center' }} onClose={handleCloseAlert} open={floatrAlert.visible} autoHideDuration={6000}>
          <Alert onClose={handleCloseAlert} severity={floatrAlert.severity} >
            {floatrAlert.message}
          </Alert>
        </Snackbar>
    </Grid>
    );
};

Edit.propTypes = {
    id: PropTypes.number
};

export default Edit;