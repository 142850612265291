import React, { Component, useState } from 'react';
import firebase from 'firebase/app';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';

import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import ProtectedRoutes from './ProtectedRoutes';
import UnProtectedRoutes from './UnProtectedRoutes';
//apollo
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { ApolloProvider } from '@apollo/react-hooks';

const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

const App = props => {
  const { idToken } = props;

  // Create an http link:
  const httpLink = new HttpLink({
    uri: 'https://quintas-hasura.costabonita.mx/v1/graphql',
    headers: {
      Authorization: `Bearer ${idToken}`
      // "x-hasura-admin-secret": "costabonitasistemas2021",
      // "content-type":  "application/json"
    }
  });

  // Create a WebSocket link:
  const wsLink = new WebSocketLink({
    uri: 'wss://quintas-hasura.costabonita.mx/v1/graphql',
    options: {
      reconnect: true,
      connectionParams: {
        headers: {
          Authorization: `Bearer ${idToken}`
          // "x-hasura-admin-secret": "",
          // "content-type":  "application/json"
        }
      }
    }
  });

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink
  );

  const createApolloClient = () => {
    return new ApolloClient({
      link: link,
      cache: new InMemoryCache()
    });
  };

  //apollo end
  const client = createApolloClient();
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Router history={browserHistory}>
          {/* <Routes authState={authState} loginCallback={loginCallback.bind(this)} registerCallback={registerCallback.bind(this)} logoutCallback={logoutCallback.bind(this)} handleAuthState={handleAuthState.bind(this)} /> */}
          <Routes />
        </Router>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;
