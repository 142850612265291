import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  //ProductList as ProductListView,
  UserList as UserListView,
  CreateUser as CreateUserView,
  EditUser as EditUserView,
  Typography as TypographyView,
  Icons as IconsView,
  Account as AccountView,
  Settings as SettingsView,
  SignUp as SignUpView,
  // SignIn as SignInView,
  NotFound as NotFoundView,
  //real routes
  Visits as VisitsView,
  Concierge as ConciergeView,
  ConciergeView as CalendarView,
  Newsletter as NewsletterView,
  Arrivals as ArrivalsView,
  ArrivalCreate as ArrivalCreateView,
  ArrivalEdit as ArrivalEditView,
  News as NewsView,
  Directory as DirectoryView,
  Provider as ProviderView,
  Palapa as PalapaView,
  RulesAndRegulations as RulesAndRegulationsView,
  Admin as AdminView,
  Validation as ValidationView,
  EditUser
} from './views';
import Rate from 'views/Provider/components/Rate/Rate';
import Motions from 'views/Motions/Motions';
import Minutes from 'views/Minutes/Minutes';
import NewOwners from 'views/NewOwners/NewOwners';

const Routes = props => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/dashboard"
      />
      {/* <RouteWithLayout 
        {...props}
        component={ValidationView}
        exact
        layout={MinimalLayout}
        path="/validation"
      /> */}
      <RouteWithLayout
        {...props}
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        {...props}
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        {...props}
        component={AdminView}
        exact
        layout={MainLayout}
        path="/admin"
      />
      <RouteWithLayout
        {...props}
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout 
        {...props}
        component={ArrivalsView}
        exact
        layout={MainLayout}
        path="/arrivals"
      />
      <RouteWithLayout 
        {...props}
        component={ArrivalCreateView}
        exact
        layout={MainLayout}
        path="/arrivals/create"
      />
      <RouteWithLayout 
        {...props}
        component={ArrivalEditView}
        exact
        layout={MainLayout}
        path="/arrivals/edit/:id"
      />
      <RouteWithLayout 
        {...props}
        component={VisitsView}
        exact
        layout={MainLayout}
        path="/visits"
      />
      <RouteWithLayout 
        {...props}
        component={ConciergeView}
        exact
        layout={MainLayout}
        path="/concierge"
      />
      <RouteWithLayout
        {...props}
        component={Motions}
        exact
        layout={MainLayout}
        path="/motions"
      />
      <RouteWithLayout
        {...props}
        component={CalendarView}
        exact
        layout={MainLayout}
        path="/concierge/calendar/:id"
      />
      <RouteWithLayout 
        {...props}
        component={NewsletterView}
        exact
        layout={MainLayout}
        path="/newsletter"
      />
      <RouteWithLayout 
        {...props}
        component={NewsView}
        exact
        layout={MainLayout}
        path="/news"
      />
       <RouteWithLayout 
        {...props}
        component={Minutes}
        exact
        layout={MainLayout}
        path="/minutes"
      />
      <RouteWithLayout
      {...props}
      component={NewOwners}
      exact
      layout={MainLayout}
      path="/newownersinfo"
      />
      <RouteWithLayout 
        {...props}
        component={DirectoryView}
        exact
        layout={MainLayout}
        path="/directory"
      />
      <RouteWithLayout 
        {...props}
        component={ProviderView}
        exact
        layout={MainLayout}
        path="/provider"
      />
      <RouteWithLayout 
        {...props}
        component={Rate}
        exact
        layout={MainLayout}
        path="/provider/rate/:id"
      />
      <RouteWithLayout 
        {...props}
        component={PalapaView}
        exact
        layout={MainLayout}
        path="/palapa"
      />
      <RouteWithLayout 
        {...props}
        component={RulesAndRegulationsView}
        exact
        layout={MainLayout}
        path="/rulesandregulations"
      />
      <RouteWithLayout 
        {...props}
        component={UserListView}
        exact
        layout={MainLayout}
        path="/users"
      />
      <RouteWithLayout
        {...props}
        component={CreateUserView}
        exact
        layout={MainLayout}
        path="/users/create"
      />
      <RouteWithLayout
        {...props}
        component={EditUserView}
        exact
        layout={MainLayout}
        path="/users/edit/:id"
      />
       <RouteWithLayout
        {...props}
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      {/* <RouteWithLayout
        {...props}
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />  */}
      <RouteWithLayout
        {...props}
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
