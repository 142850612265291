import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer, Button } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import EmailIcon from '@material-ui/icons/Email';
import FastFoodIcon from '@material-ui/icons/Fastfood';
import FolderIcon from '@material-ui/icons/Folder';
import GavelIcon from '@material-ui/icons/Gavel';
import FlightLandIcon from '@material-ui/icons/FlightLand';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SettingsIcon from '@material-ui/icons/Settings';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import { AiFillCarryOut } from "react-icons/ai";
import { Profile, SidebarNav } from './components';
import {useAuthFirebase} from "../../../../views/Auth/react-auth-firebase"
//import  './components/Sidebar.css';
import { Scrollbars } from 'react-custom-scrollbars';
//import { render } from 'node-sass';
import { useSubscription,useMutation,useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Admin } from 'views';
import data from 'views/Arrivals/data';
import { graphql } from 'graphql';
import firebase, { auth } from "firebase/app";
import "firebase/auth";
import "firebase/database";

const GETUSERROLEBYEMAIL = gql `
subscription getRole($email: String!) {
  users:users(where: {email: {_eq: $email}}) {
    first_name
    last_name
    email
    roles
  }
}`

const GETUSERS = gql `
subscription getRoles {
  users(where: {email: {_eq: "polo.nu16@gmail.com"}}) {
    first_name
    last_name
    email
    roles
  }
}
`

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
      boxShadow: 'none',
      backgroundColor: 'rgb(66, 63, 63)',
    }
  },
  root: {
    // backgroundColor: 'gray',
    //backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: 'rgb(66, 63, 63)',
    
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0),
    backgroundColor: "gray"
  },
  nav: {
    marginBottom: theme.spacing(2)
  },
  backColor: {
    backgroundColor: "gray"
  },

  backGroundColor: {
    boxShadow: 'none',
    backgroundColor: 'rgba(112, 87, 87, 0.25)',
  },

}));

const Sidebar = props => {
  var auth = firebase.auth();
  const { onSidebarOpen, open, variant, onClose, className, handleAuthState,userType, ...rest } = props;
  const {handdleSignOut} = useAuthFirebase();
  const classes = useStyles();
  const emailCustom =  firebase.auth().currentUser && firebase.auth().currentUser.email;
  const {data,loading,error} = useSubscription(GETUSERROLEBYEMAIL,{variables:{email:emailCustom}});
  const [isAdmin,useIsAdmin] = useState(true);
  const userCondtion = "prueba";
  
 


  function closeNavBar()  {
     const popo = data.users.map(user => user.roles).toString();
    console.log(data.users);
    console.log("que es? " + rest.roles + rest.email);
  }

  const usersData = () => {data.users.map(user =>  
         closeNavBar(user.roles))
    }  

    // firebase.auth().onAuthStateChanged(function(user) {
    //   if (user) {
    //     // User is signed in.
    //     console.log("positivo " + user.email);
    //     sentEmail(user.email);
      
    //   } else {
    //     // No user is signed in.
    //   }
    // })

    
const pagesForUsers =
 [
  {
    title: 'Dashboard',
    href: '/dashboard',
    icon: <DashboardIcon />
    
  },
  {
    title: 'Concierge',
    href: '/concierge',
    icon: <RoomServiceIcon />
  },

  // {
  //   title: 'Motions',
  //   href:'/motions',
  //   icon: <AiFillCarryOut/>
  // },
  {
    title: 'Maintenance Reports',
    href: '/newsletter',
    icon: <EmailIcon />
  },
  {
    title: 'News',
    href: '/news',
    icon: <FiberNewIcon />
  },
  {
    title: 'Minutes',
    href: '/minutes',
    icon: <NewReleasesIcon/>
  },
  {
    title: 'New Owners Info',
    href: '/newownersinfo',
    icon: <EmojiPeopleIcon/>
  },
  {
    title: 'Owners Directory',
    href: '/directory',
    icon: <FolderSharedIcon />
  },
  {
    title: 'Suppliers',
    href: '/provider',
    icon: <FolderSharedIcon />
  },
  {
    title: 'Palapa',
    href: '/palapa',
    icon: <FastFoodIcon />
  },
  {
    title: 'Guest Check In',
    href: '/arrivals',
    icon: <FlightLandIcon />
  },
  {
    title: 'Daily Visitors',
    href: '/visits',
    icon: <AirportShuttleIcon />
  },
  {
    title: 'Rules & Regulations',
    href: '/rulesandregulations',
    icon: <GavelIcon />
  },
  {
    title: 'Administration',
    href: '/Admin',
    icon: <FolderIcon />
  },
  {
    title: 'Profile',
    href: '/account',
    icon: <AccountBoxIcon />
  }
];

const pagesForPortico =
 [
  {
    title: 'Dashboard',
    href: '/dashboard',
    icon: <DashboardIcon />
    
  },
  {
    title: 'Daily Visitors',
    href: '/visits',
    icon: <AirportShuttleIcon />
  },
  {
    title: 'Profile',
    href: '/account',
    icon: <AccountBoxIcon />
  }
];

const pages = [
    {
      title: 'Dashboard',
      href: '/dashboard',
      icon: <DashboardIcon />
      
    },
    {
      title: 'Concierge',
      href: '/concierge',
      icon: <RoomServiceIcon />
    },

    // {
    //   title: 'Motions',
    //   href:'/motions',
    //   icon: <AiFillCarryOut/>
    // },
    {
      title: 'Maintenance Reports',
      href: '/newsletter',
      userType: userCondtion,
      icon: <EmailIcon />
     
    },
    {
      title: 'News',
      href: '/news',
      icon: <FiberNewIcon />
    },
    {
      title: 'Minutes',
      href: '/minutes',
      icon: <NewReleasesIcon/>
    },
    {
      title: 'New Owners Info',
      href: '/newownersinfo',
      icon: <EmojiPeopleIcon/>
    },
    {
      title: 'Owners Directory',
      href: '/directory',
      icon: <FolderSharedIcon />
    },
    {
      title: 'Suppliers',
      href: '/provider',
      icon: <FolderSharedIcon />
    },
    {
      title: 'Palapa',
      href: '/palapa',
      icon: <FastFoodIcon />
    },
    {
      title: 'Guest Check In',
      href: '/arrivals',
      icon: <FlightLandIcon />
    },
    {
      title: 'Daily Visitors',
      href: '/visits',
      icon: <AirportShuttleIcon />
    },
    {
      title: 'Rules & Regulations',
      href: '/rulesandregulations',
      icon: <GavelIcon />
    },
    {
      title: 'Administration',
      href: '/Admin',
      icon: <FolderIcon />
    },
    {
      title: 'Profile',
      href: '/account',
      icon: <AccountBoxIcon />
    }, 
    // {
    //   title: 'Settings',
    //   href: '/settings',
    //   icon: <SettingsIcon />
    // },
    {
      title: 'Users',
      href: '/users',
      icon: <AccountBoxIcon />
    }
];

const pagesForReception = [
  {
    title: 'Dashboard',
    href: '/dashboard',
    icon: <DashboardIcon />
    
  },
  {
    title: 'Concierge',
    href: '/concierge',
    icon: <RoomServiceIcon />
  },
  {
    title: 'Guest Check In',
    href: '/arrivals',
    icon: <FlightLandIcon />
  },
  {
    title: 'Daily Visitors',
    href: '/visits',
    icon: <AirportShuttleIcon />
  },
  {
    title: 'Profile',
    href: '/account',
    icon: <AccountBoxIcon />
  }, 
  // {
  //   title: 'Settings',
  //   href: '/settings',
  //   icon: <SettingsIcon />
  // },
];


if (loading) 
return <p>loading</p>

return ( <Drawer
  anchor="left"
  class="scroll scroll4"
  classes={{ paper: classes.drawer }}
  onClose={onClose}
  open={open}
  variant={variant}
> 

  <div
  class="scroll scroll4"
    {...rest}
    className={clsx(classes.root, className)}>

      <Profile {...props} />
      <Divider className={classes.divider} /> 
      <SidebarNav
        userCondition={userCondtion}
        className={classes.nav}
        pages={
          (data.users.length > 0 && data.users[0].roles === 'admin')
            ? pages
            : data.users[0].roles === 'portico'
            ? pagesForPortico
            : data.users[0].roles === 'recepcion'
            ? pagesForReception
            : pagesForUsers
        }
    /> 
    
    <Divider className={classes.divider} />
    <Button onClick={handdleSignOut}  class="btn btn-danger"
    >Logout </Button> <br></br>
  </div>
</Drawer>);

};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
