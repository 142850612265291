import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Modal, Backdrop, Fade } from '@material-ui/core';
import VisitForm from './Components/VisitForm';
import { SearchInput } from 'components';
import firebase, { auth } from "firebase/app";
import { gql } from 'apollo-boost';
import { useSubscription,useMutation,useQuery } from '@apollo/react-hooks';


const GETUSERROLEBYEMAIL = gql `
subscription getRole($email: String!) {
  users:users(where: {email: {_eq: $email}}) {
    first_name
    last_name
    email
    roles
  }
}`

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  modal: {
    position:'absolute',
    top:'10%',
    left:'10%',
    overflow:'scroll',
    height:'100%',
    display:'block'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #333',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const UsersToolbar = props => {
  const { className, query, setQuery,userData, ...rest } = props;
  const [open, setOpen] = React.useState(false);
  const emailCustom =  firebase.auth().currentUser && firebase.auth().currentUser.email;
  const {data,loading,error} = useSubscription(GETUSERROLEBYEMAIL,{variables:{email:emailCustom}});
  const {
    data: userDatas,
    loading: userLoadingError,
    error: userDataError
  } = useSubscription(GETUSERROLEBYEMAIL, {
    variables: { email: emailCustom }
  });


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        {userDatas?.users[0]?.roles == 'admin' || userDatas?.users[0]?.roles == 'user'
          ?(<Button
            color="primary"
            variant="contained"
            onClick={handleOpen}
          >
            New Visit
          </Button>
          ) : (
            <div></div>
          )}
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search name"
          onChange={setQuery}
          value={query}
        />
      </div>
      
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            { <VisitForm  handleClose={handleClose.bind(this)} /> }
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

UsersToolbar.propTypes = {
  className: PropTypes.string,
  query: PropTypes.string,
  setQuery: PropTypes.func
};

export default UsersToolbar;
